import { render, staticRenderFns } from "./ConventionRegistrationSettings.vue?vue&type=template&id=025dfabc&scoped=true&"
import script from "./ConventionRegistrationSettings.vue?vue&type=script&lang=js&"
export * from "./ConventionRegistrationSettings.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "025dfabc",
  null
  
)

export default component.exports